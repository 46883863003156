import styled from "styled-components";
import { FadeInProps, fadeInAnimation } from "../../Homepage.style";

export const SocialsContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SocialsContentContainer = styled.div<FadeInProps>`
  margin-top: 30px;
  width: 80%;

  max-width: 200px;

  display: flex;
  justify-content: space-between;

  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s;
  ${({ $isVisible }) => $isVisible && fadeInAnimation}
`;

export const SocialIcon = styled.img`
  cursor: pointer;

  height: 75px;
  width: 40px;

  @media (min-width: 768px) {
    height: 95px;
    width: 50px;

    &:hover {
      transform: scale(1.05);
    }
  }
`;
