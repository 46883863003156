import React from "react";
import { ButtonSecondary, H2 } from "../../../../App.style";
import {
  WrozbaSelectorContainer,
  WrozbaSelectorTitleContainer,
  WrozbaSelectorContentContainer,
} from "./Hero.style";

import tarotIcon from "../../../../assets/hero/tarot-card.png";
import przepowiedniaIcon from "../../../../assets/hero/crystal-ball.png";
import dreamIcon from "../../../../assets/hero/dream.png";
import HeroSelectorCard from "./HeroSelectorCard";
import { useTranslation } from "react-i18next";

interface HeroSelectorProps {
  handleTarotClick: () => void;
  handlePrzepowiedniaClick: () => void;
  handleDreamsClick: () => void;
  closeSelector: () => void;
}

const HeroSelector: React.FC<HeroSelectorProps> = ({
  handleDreamsClick,
  handlePrzepowiedniaClick,
  handleTarotClick,
  closeSelector,
}) => {
  const { t } = useTranslation();
  return (
    <WrozbaSelectorContainer>
      <WrozbaSelectorTitleContainer>
        <H2>{t("homepage.wrozbaSelector.title")}</H2>
      </WrozbaSelectorTitleContainer>

      <WrozbaSelectorContentContainer>
        <div>
          <HeroSelectorCard
            title={"Tarot"}
            description={t("homepage.wrozbaSelector.tarotDescription")}
            icon={tarotIcon}
            type="tarot"
            button={handleTarotClick}
          />
        </div>

        <div>
          <HeroSelectorCard
            title={t("homepage.wrozbaSelector.prophecy")}
            description={t("homepage.wrozbaSelector.prophecyDescription")}
            icon={przepowiedniaIcon}
            type="przepowiednia"
            button={handlePrzepowiedniaClick}
          />
        </div>

        <div>
          <HeroSelectorCard
            title={t("homepage.wrozbaSelector.dream")}
            description={t("homepage.wrozbaSelector.dreamDescription")}
            icon={dreamIcon}
            type="sen"
            button={handleDreamsClick}
          />
        </div>

        {/* <div>
          <HeroSelectorCard
            title={t("homepage.wrozbaSelector.coin")}
            description={t('homepage.wrozbaSelector.coinDescription')}
            icon={coinIcon}
            type="coin"
            button={() => alert("mordeczko monetka")}
          />
        </div> */}
      </WrozbaSelectorContentContainer>

      <div style={{ marginTop: 70, display: "flex", gap: 20 }}>
        <ButtonSecondary onClick={closeSelector} style={{ color: "white" }}>
          {t("fortuneModal.buttons.close")}
        </ButtonSecondary>
      </div>
    </WrozbaSelectorContainer>
  );
};

export default HeroSelector;
