import { translationsPl } from "./pl";

export const translationsEn: typeof translationsPl = {
  homepage: {
    hero: {
      moreFortunes: "See other available fortunes",
      title: {
        part1: "Ready to know the future?",
        part2: "waits to reveal it to you.",
      },
      subtitle: {
        part1: "Tarot reading, personalized prophecies, dream interpretations.",
        part2: "The future is closer than you think!",
      },
      buttonText: "DISCOVER THE FUTURE",
      buttonEmptyText: "HOW DOES IT WORK?",
    },
    tarotDetailed: {
      before: "Discover your cards",
      after:
        "The Mystic has looked into the cards, they have something to tell you",
    },
    wrozbaSelector: {
      title: "Which fortune will you choose today?",
      prophecy: "Prophecy",
      dream: "Dream interpretation",
      coin: "Coin toss",
      next: "Next",

      tarotDescription:
        "Interpretation of the selected number of Tarot cards with consideration of the chosen theme.",
      prophecyDescription:
        "Personalized prophecy related to the chosen theme and time period.",
      dreamDescription:
        "Detailed interpretation of a dream, based on its description.",
      coinDescription: "Free coin toss to help make important decisions.",
      form: {
        cardCount: "Number of Cards",
        stack: "Choose a Stack",
        selectcard1: "Choose 1 card",
        selectcard2: "Choose 2 cards",
        selectcard3: "Choose 3 cards",
        selectcard5: "Choose 5 cards",
        needsLogin: "Requires login",
        card1: {
          title: "1 Card",
          price: "Free",
          description:
            "Concise insight and hints in your chosen category. Available once every 24 hours.",
        },
        card2: {
          title: "2 Cards",
          price: "~1.3 USD",
          description:
            "Comprehensive analysis and deeper understanding of your situation.",
        },
        card3: {
          title: "3 Cards",
          price: "~1.3 USD",
          description:
            "Detailed analysis and a precise insight into your future. Provides general guidance.",
        },
        card5: {
          title: "5 Cards",
          price: "~2.5 USD",
          description:
            "Very detailed and comprehensive communication about your future. Provides valuable advice and guidance.",
        },
      },
    },
    introduction: {
      title:
        "Just one click separates you from experiencing the first-of-its-kind AI fortune-telling.",
      subtitle: {
        part1:
          "With us, the future becomes more fascinating, and every moment of prediction becomes a unique event.",
        part2:
          "Welcome to the era where artificial intelligence becomes your personal fortune teller!",
      },
    },
    howItWorks: {
      title: "Three steps to the fortune",
      step1: {
        title: "Click and enter the world of fortune-telling",
        line1: `Start your adventure with AI fortune-telling by clicking the 'Fortune' button in the navigation bar, or click `,
        line2:
          "After clicking, first select between tarot and predictions, and then fill out a short form to receive the most personalized fortune possible.",
        here: "here",
      },
      step2: {
        title: `Make Payment`,
        text: "After filling out the form, you will be redirected to a secure payment process. We accept various payment methods to make the process easier for you.",
      },
      step3: {
        title: "Receive Your Fortune",
        text: "After payment approval, your fortune will be generated by our AI system and delivered directly to you.",
      },
    },
    faq: {
      title: "FAQ - Frequently Asked Questions",
      question1: {
        title: "How does AI fortune-telling work?",
        text: "Our AI fortunes utilize advanced artificial intelligence algorithms to analyze your inquiries and provide personalized, thoughtful responses. This process combines elements of fortune-telling and artificial intuition, creating a unique future-predicting experience.",
      },
      question2: {
        title: "Is my data safe?",
        text: "Yes, your data is completely safe. All payments are handled by STRIPE, a leader in the digital payment industry, ensuring a high level of security and data protection.",
      },
      question3: {
        title: "Do I need to register to use the service?",
        text: `"Using our services does not require registration. However, if you decide to create an account, you will gain access to additional features. Besides the ability to view the history of your fortunes on a dedicated page, which will make it easier for you to track your predictions later, once logged in, you will also have access to a special option: fortune-telling with one card once every 24 hours completely for free. This exclusive convenience is available only to registered users.`,
      },
      question4: {
        title: "Are AI fortunes always positive?",
        text: "We strive to ensure our fortunes are balanced and authentic. This means they are not always exclusively positive, but always aim to provide inspiration and perspective.",
      },
      question5: {
        title:
          "What should I do if I have a technical problem with the service?",
        text: `If you encounter any technical problems with our service, please use the button below or the 'Contact' option available in the navigation bar. This will open a contact form where you can describe your problem, and we will try to resolve it as quickly as possible.`,
        buttonText: "Contact",
      },
      question6: {
        title: "How can I provide feedback or suggest improvements?",
        text: "Your opinions and suggestions are extremely valuable to us. If you have any comments or ideas for improvements to our service, we encourage you to use the contact form. To access it, you can use the button located below this message or choose the 'Contact' option available in the navigation bar. Your insights will help us to further enhance our services.",
      },
    },
    socials: {
      title: "Find us on",
    },
  },
  fortuneModal: {
    requiredError: "This field is required",
    select: "Select",
    step1: {
      title: "Prophecy",
      name: "Name",
      age: "Age",
      gender: "Gender",
      profession: "Profession",
      saveData: "Save data for future fortunes",
      proffesionPlaceholder: "Find your profession",
    },
    step2: {
      title: "What mysteries of the future would you like to uncover?",
      fortuneTopic: "Fortune Topic",
      time: "Time Period",
      mood: "Your current mood?",
      relationship_status: "Relationship status",
    },
    step3: {
      title: "Make sure everything is correct",
      price: "Price",
      price2: "~1.3 USD",
      terms: {
        accept: "I Accept ",
        terms: "Terms and Conditions",
        and: " and ",
        privacy: "Privacy Policy",
        error: "To continue, you must accept the terms and conditions.",
      },
    },

    buttons: {
      close: "CLOSE",
      back: "BACK",
      continue: "CONTINUE",
      payment: "TO PAYMENT",
    },
  },
  fortunesList: {
    title: "My Fortunes",
    tarot: "Tarot",
    prophecy: "Prophecy",
    noFortunes: {
      row1: "It seems that you don't have any fortunes yet",
      row2: "To change that, click the button",
      buttonText: "FORTUNE",
    },
    loading: "Loading...",
    seeMore: "SEE MORE",
  },
  fortuneDetailed: {
    title: "Mysticer has looked into your future and has something to tell you",
    buttons: {
      copied: "COPIED",
      copy: "COPY LINK",
      myFortunes: "MY FORTUNES",
      homepage: "HOMEPAGE",
    },
  },
  dream: {
    detailed: {
      title: ", here is your dream interpretation",
    },
    form: {
      title: "Dream Interpretation",
      name: "Name",
      description: "Dream Description",
      errorLess: "Dream description must be at least 20 characters.",
      errorMore: "Dream description cannot exceed 300 characters.",
      price: "Price",
      loadingTitle:
        "In the mysterious corners of time, Mysticer discovers the meaning of your dreams.",
      loadingSubtitle: "Rest for a while - interpretation is near.",
      info1:
        "Note: Please provide a clear and understandable dream description. This will allow us to interpret your experience more accurately.",
      info2:
        "We are not responsible for thoughtless content entered in the form.",
    },
  },

  navbar: {
    homepage: "Homepage",
    myFortunes: "My fortunes",
    fortune: "Fortune",
    howItWorks: "How it works?",
    help: "Help",
    login: "Sign in",
    contact: "Contact",
    popover: {
      account: "Account",
      settings: "Settings",
      logout: "Sign out",
    },
  },
  helpModal: {
    form: {
      title: "Contact",
      subLine1: "Use this form to share your feedback or suggestions with us.",
      subLine2:
        "If you encounter a problem, please check the option below - it will help us to act faster.",
      problem: "I am reporting an issue",
      messageTitle: "Title",
      fieldRequired: "This field is required",
      message: "Message",
      buttons: {
        close: "CLOSE",
        send: "SEND",
      },
    },

    success: {
      line1: "Thank you for submitting the form.",
      line2: "All feedback and suggestions are valuable to us.",
      line3:
        "If you've reported a problem, we will prioritize it. Expect an email response soon.",
    },
    error: {
      line1: "Your submission could not be sent due to technical difficulties.",
      line2: "Please check your internet connection or try again later.",
    },
  },

  wrozbaResult: {
    loading: {
      title:
        "In the mysterious corners of time, Mysticer uncovers your future.",
      subtitle: "Rest for a moment - your fortune is near.",
      sentences: {
        1: "Laying out the cards of the future in cyberspace...",
        2: "Sorting stars in the cosmic waiting room...",
        3: "Dusting off the crystal ball...",
        4: "Mixing magical ingredients in the fortune-telling cauldron...",
        5: "Coffee break...",
        6: "Mysticer is reviewing the universe's data collections...",
        7: "Closing the portal to another reality...",
        8: "Counting constellations...",
        9: "Diluting the mystical fog...",
        10: "Reviewing possibilities...",
        11: "Catching meteorites...",
        12: "Discovering new planets...",
        13: "Studying the syntax of the future...",
        14: "Counting ones and zeros...",
      },
    },
    error: {
      title: "Something went wrong with generating your fortune.",
      subLine1: "The refund process has been forwarded to Stripe.",
      subLine2:
        "If the funds are not returned within 5 days, please contact us through our submission form.",
      subLine3: "We apologize for the inconvenience.",
      homepage: "Home Page",
    },
  },

  loginModal: {
    login: {
      google: "SIGN IN WITH GOOGLE",
      error: "An error occurred. Try again.",
      wrongEmail: "Incorrect email address",
      password: "Password",
      forgotPassword: "Forgot password",
      login: "Log in",
      dontHaveAcc: "Don't have an account?",
      register: "Sign up",
    },
    register: {
      title: "Create your account",
      password: "Password",
      rePassword: "Repeat password",
      signUp: "Create account",
      haveAnAccount: "Already have an account?",
      error: {
        wrongEmail: "Incorrect email address",
        tooShort: "Password must be at least 8 characters long",
        same: "Passwords must be the same",
        alreadyInUse: "Email address is already in use.",
        wtf: "An error occurred. Try again.",
      },
    },
    passwordReset: {
      title: "Reset password",
      remind: "Remind password",
      backToLogin: "Back to login screen",
      sent: {
        line1:
          "A link to reset your password has been sent to your email address.",
        line2:
          "Check your inbox and spam folder to continue the password change process.",
      },
    },
    or: "or",
  },
  settings: {
    title: "Settings",
    userData: {
      myData: "My Data",
      name: "Name",
      age: "Age",
      profession: "Profession",
      required: "This field is required",
      dataSaved: "Your data has been saved!",
      updateButton: "Update Data",
    },
    passwordChange: {
      title: "Password Change",
      current: "Current",
      password: "Password",
      rePassword: "Repeat Password",
      saveData: "Update Password",
      savedPassword: "Password has been successfully changed!",
      error: {
        lengthError: "The password must be at least 8 characters long",
        notTheSame: "The passwords do not match!",
        wrongPassword: "Wrong password",
        something: "Something went wrong, please try again.",
      },
    },
    deleteAccount: {
      title: "Delete Account",
      subtitle1: "Once you delete your account, there is no going back. ",
      subtitle2: "Enter your email to confirm the deletion of your account.",
      buttonText: "Delete account",
      email: "Email",
      deleted: "Your account will be deleted within 48 hours.",
    },
    button: {
      close: "CLOSE",
    },
  },
  languageSelector: {
    title: "Select Your Language",
  },
  serviceWork: {
    title: "Technical Maintenance",
    subtitle: "We will be back at {{backTime}} GMT+1",
  },

  footer: {
    legal: {
      terms: "Terms of use",
      privacy: "Privacy",
    },
  },

  tarot: {
    title: "Tarot cards reading",
    //główna arkana
    card1: {
      name: "The Fool",
      description: "",
    },
    card2: {
      name: "The Magician",
      description: "",
    },
    card3: {
      name: "The High Priestess",
      description: "",
    },
    card4: {
      name: "The Empress",
      description: "",
    },
    card5: {
      name: "The Emperor",
      description: "",
    },
    card6: {
      name: "The Hierophant",
      description: "",
    },
    card7: {
      name: "The Lovers",
      description: "",
    },
    card8: {
      name: "The Chariot",
      description: "",
    },
    card9: {
      name: "Strenght",
      description: "",
    },
    card10: {
      name: "The Hermit",
      description: "",
    },
    card11: {
      name: "Wheel Of Fortune",
      description: "",
    },
    card12: {
      name: "Justice",
      description: "",
    },
    card13: {
      name: "The Hanged Man",
      description: "",
    },
    card14: {
      name: "Death",
      description: "",
    },
    card15: {
      name: "Temperance",
      description: "",
    },
    card16: {
      name: "Devil",
      description: "",
    },
    card17: {
      name: "The Tower",
      description: "",
    },
    card18: {
      name: "The Star",
      description: "",
    },
    card19: {
      name: "The Moon",
      description: "",
    },
    card20: {
      name: "The Sun",
      description: "",
    },
    card21: {
      name: "The Judgement",
      description: "",
    },
    card22: {
      name: "The World",
      description: "",
    },
    //wszystkie cups

    card23: {
      name: "Ace of Cups",
      description: "",
    },
    card24: {
      name: "Two of Cups",
      description: "",
    },
    card25: {
      name: "Three of Cups",
      description: "",
    },
    card26: {
      name: "Four of Cups",
      description: "",
    },
    card27: {
      name: "Five of Cups",
      description: "",
    },
    card28: {
      name: "Six of Cups",
      description: "",
    },
    card29: {
      name: "Seven of Cups",
      description: "",
    },
    card30: {
      name: "Eight of Cups",
      description: "",
    },
    card31: {
      name: "Nine of Cups",
      description: "",
    },
    card32: {
      name: "Ten of Cups",
      description: "",
    },
    card33: {
      name: "Page of Cups",
      description: "",
    },
    card34: {
      name: "Knight of Cups",
      description: "",
    },
    card35: {
      name: "Queen of Cups",
      description: "",
    },
    card36: {
      name: "King of cups",
      description: "",
    },

    //miecze

    card37: {
      name: "Ace of Swords",
      description: "",
    },
    card38: {
      name: "Two of Swords",
      description: "",
    },
    card39: {
      name: "Three of Swords",
      description: "",
    },
    card40: {
      name: "Four of Swords",
      description: "",
    },
    card41: {
      name: "Five of Swords",
      description: "",
    },
    card42: {
      name: "Six of Swords",
      description: "",
    },
    card43: {
      name: "Seven of Swords",
      description: "",
    },
    card44: {
      name: "Eight of Swords",
      description: "",
    },
    card45: {
      name: "Nine of Swords",
      description: "",
    },
    card46: {
      name: "Ten of Swords",
      description: "",
    },
    card47: {
      name: "Page of Swords",
      description: "",
    },
    card48: {
      name: "Knight of Swords",
      description: "",
    },
    card49: {
      name: "Queen of Swords",
      description: "",
    },
    card50: {
      name: "King of Swords",
      description: "",
    },

    //buławy

    card51: {
      name: "Ace of Wands",
      description: "",
    },
    card52: {
      name: "Two of Wands",
      description: "",
    },
    card53: {
      name: "Three of Wands",
      description: "",
    },
    card54: {
      name: "Four of Wands",
      description: "",
    },
    card55: {
      name: "Five of Wands",
      description: "",
    },
    card56: {
      name: "Six of Wands",
      description: "",
    },
    card57: {
      name: "Seven of Wands",
      description: "",
    },
    card58: {
      name: "Eight of Wands",
      description: "",
    },
    card59: {
      name: "Nine of Wands",
      description: "",
    },
    card60: {
      name: "Ten of Wands",
      description: "",
    },
    card61: {
      name: "Page of Wands",
      description: "",
    },
    card62: {
      name: "Knight of Wands",
      description: "",
    },
    card63: {
      name: "Queen of Wands",
      description: "",
    },
    card64: {
      name: "King of Wands",
      description: "",
    },

    //denars

    card65: {
      name: "Ace of Pentacles",
      description: "",
    },
    card66: {
      name: "Two of Pentacles",
      description: "",
    },
    card67: {
      name: "Three of Pentacles",
      description: "",
    },
    card68: {
      name: "Four of Pentacles",
      description: "",
    },
    card69: {
      name: "Five of Pentacles",
      description: "",
    },
    card70: {
      name: "Six of Pentacles",
      description: "",
    },
    card71: {
      name: "Seven of Pentacles",
      description: "",
    },
    card72: {
      name: "Eight of Pentacles",
      description: "",
    },
    card73: {
      name: "Nine of Pentacles",
      description: "",
    },
    card74: {
      name: "Ten of Pentacles",
      description: "",
    },
    card75: {
      name: "Page of Pentacles",
      description: "",
    },
    card76: {
      name: "Knight of Pentacles",
      description: "",
    },
    card77: {
      name: "Queen of Pentacles",
      description: "",
    },
    card78: {
      name: "King of Pentacles",
      description: "",
    },
  },
};
